import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsDownload, BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT, HMIL_CUSTOM_CHARGING_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { IoSearchOutline } from 'react-icons/io5';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';
import { formatAmount } from 'components/common/utils';

const HMILCustomChargingReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeStation, setChangeStation] = useState(false);

  const hmilreportlist = useSelector((state) => state.HMILCustomChargingReport.HMILCustomChargingReport);

  const isLoader = useSelector((state) => state.HMILCustomChargingReport.isLoading);
  const page = useSelector((state) => state.HMILCustomChargingReport.page);
  const totalData = useSelector((state) => state.HMILCustomChargingReport.total);
  const limit = useSelector((state) => state.HMILCustomChargingReport.limit);
  const totalPages = useSelector((state) => state.HMILCustomChargingReport.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');

  const HMILCustomChargingReport = useCallback(
    (value) => {
      if (searchText) {
        const HMILCustomChargingReportData = {
          ...value,
          search: searchText,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: HMIL_CUSTOM_CHARGING_REPORT,
          payload: HMILCustomChargingReportData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else if (!_.isEmpty(userProfileStations)) {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
        };
        dispatch({
          type: HMIL_CUSTOM_CHARGING_REPORT,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: HMIL_CUSTOM_CHARGING_REPORT,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
        const filterData = {
          ...advanceFilterData,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          wallet_type: '',
          type: '',
        };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        HMILCustomChargingReport(data);
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', wallet_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        HMILCustomChargingReport(data);
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        HMILCustomChargingReport(data);
      } else if (advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, wallet_type: '', type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        HMILCustomChargingReport(data);
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        HMILCustomChargingReport(data);
      } else if (advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        HMILCustomChargingReport(data);
      } else if (advanceFilterData.wallet_type === 'All') {
        const filterData = { ...advanceFilterData, wallet_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        HMILCustomChargingReport(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        HMILCustomChargingReport(data);
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: userProfileStations,
      };
      HMILCustomChargingReport(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      HMILCustomChargingReport(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData, searchText]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleSearch = () => {
    HMILCustomChargingReport({});
  };

  useEffect(() => {
    if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        charging_station: userProfileStations,
      };
      HMILCustomChargingReport(data);
    } else {
      HMILCustomChargingReport();
    }
  }, []);

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && HMILCustomChargingReport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        HMILCustomChargingReport(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && HMILCustomChargingReport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        HMILCustomChargingReport(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
          const filterData = {
            ...advanceFilterData,
            charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
            wallet_type: '',
            type: '',
          };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          HMILCustomChargingReport(data);
        } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', wallet_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          HMILCustomChargingReport(data);
        } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.type === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          HMILCustomChargingReport(data);
        } else if (advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
          const filterData = { ...advanceFilterData, wallet_type: '', type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          HMILCustomChargingReport(data);
        } else if (advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          HMILCustomChargingReport(data);
        } else if (advanceFilterData.type === 'All') {
          const filterData = { ...advanceFilterData, type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          HMILCustomChargingReport(data);
        } else if (advanceFilterData.wallet_type === 'All') {
          const filterData = { ...advanceFilterData, wallet_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          HMILCustomChargingReport(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          HMILCustomChargingReport(data);
        }
      } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const filterDateData = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
          page: page.selected + 1,
        };
        HMILCustomChargingReport(filterDateData);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: searchText,
          page: page.selected + 1,
        };
        HMILCustomChargingReport(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        HMILCustomChargingReport(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        HMILCustomChargingReport(data);
      }
    },
    [sortByItem, startDate, endDate, advanceFilterData, searchText]
  );
  // Download Excel Functionality

  const downloadHMILCustomChargingReport = useCallback(() => {
    let filterData = { ...advanceFilterData };

    // Check if charging station filter needs to be adjusted
    if (advanceFilterData.charging_station === 'All' && !_.isEmpty(userProfileStations)) {
      filterData = { ...filterData, charging_station: userProfileStations };
    }

    const data = {
      ...filterData,
      excel: true,
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
      report: 'hmilcharginghistory',
      status: 'completed',
      type: 'Public',
    };

    dispatch({
      type: DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT,
      payload: data,
      cb: (res) => {
        if (res && _.get(res, 'status') === 200) {
          fileDownload(res.data, `${'HMIL Charging History Report'}.xlsx`);
        }
      },
    });
  }, [startDate, endDate, advanceFilterData, userProfileStations]);

  const downloadFile = () => {
    downloadHMILCustomChargingReport();
  };

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = (
    <SearchBox
      preIcon={<BsSearch />}
      value={searchText}
      onChange={searchHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
    />
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  const allStation = useSelector((state) => state.chargingStation.chargingStations);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const stationList = allStation.filter((x) => userProfileStations.indexOf(x.id) !== -1);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { charging_station: '', wallet_type: '', type: '' };

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  useEffect(() => {
    getAllStation();
  }, []);

  // const allWalletType = [
  //   { label: 'Book', value: 'Book' },
  //   { label: 'Booking Cancellation', value: 'Booking Cancellation' },
  //   { label: 'CMS Credit', value: 'CMS CREDIT' },
  //   { label: 'Refund', value: 'Refund' },
  //   { label: 'Online Credit', value: 'WalletTopup' },
  //   { label: 'Cash Back', value: 'Cashback' },
  //   { label: 'Extra Unit Consumed', value: 'ExtraUnits' },
  // ];

  // const allPaymentType = [
  //   { label: 'Debit', value: 'debit' },
  //   { label: 'Credit', value: 'credit' },
  // ];

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.HMILCustomChargingReport')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                        style={{ display: 'none' }}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment().startOf('month')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.submit')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.srNo')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.country')}</span>
                                <span className="ico" onClick={() => handleSorting('id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.state')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.city')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.partyId')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.ocppId')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.stationName')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.capacity')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.connctor')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.rfid')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.transactionId')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.bookingDate')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.sessionCount')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.meterStartReading')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.meterStopReading')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.sessionConsumption')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.energyRate')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.netAmount')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.igst')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.cgst')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.sgst')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.totalAmount')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.sessionStartDateTime')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.sessionStopDateTime')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.sessionDuration')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.startSOC')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.stopSOC')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.reasonToStop')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.make')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.model')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.customerName')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.ocpiCustomer')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.mobileNo')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('HMILCustomChargingReport.mobileApp')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={11} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(hmilreportlist) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('HMILCustomChargingReport.noHistoryFound')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(hmilreportlist, (item, key) => {
                              const serial_num = limit * (page - 1) + key + 1; // Adding 1 to start serial number from 1 instead of 0

                              return (
                                <tr key={`wallet-transaction-report-${serial_num}`}>
                                  {/* Display serial number directly */}
                                  <td>{serial_num}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.country', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.state', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.city', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ocpiCredential.partyId', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charger_id', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.name', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.oem.charger_capacity', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'connectorId', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'idTag', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transaction_id', '-')}</td>
                                  <td>
                                    {isLoader ? <Skeleton height={15} width={100} /> : moment(_.get(item, 'booking_start', '-')).format('DD-MM-YYYY')}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'no_of_session', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'meterstart', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'meterstop', '-')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      (_.get(item, 'meterstop', '-') - _.get(item, 'meterstart', '-')) / 1000
                                    )}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : formatAmount(_.get(item, 'invoice.price_per_unit', '-'))}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : formatAmount(_.get(item, 'invoice.subtotal', '-'))}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : _.get(item, 'invoice.gst_type', '-') === 'IGST' ? (
                                      formatAmount(_.get(item, 'invoice.subtotal', '-') * 0.09)
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : _.get(item, 'invoice.gst_type', '-') === 'SGST' ? (
                                      formatAmount(_.get(item, 'invoice.subtotal', '-') * 0.09)
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : _.get(item, 'invoice.gst_type', '-') === 'SGST' ? (
                                      formatAmount(_.get(item, 'invoice.subtotal', '-') * 0.09)
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : formatAmount(_.get(item, 'invoice.total_amount', '-'))}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      moment(_.get(item, 'booking_start', '-')).format('DD-MM-YYYY hh:mm:ss')
                                    )}
                                  </td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      moment(_.get(item, 'booking_stop', '-')).format('DD-MM-YYYY hh:mm:ss')
                                    )}
                                  </td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      (() => {
                                        const start = new Date(_.get(item, 'booking_start'));
                                        const stop = new Date(_.get(item, 'booking_stop'));
                                        const diffMs = stop - start;

                                        const hours = Math.floor(diffMs / (1000 * 60 * 60));
                                        const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
                                        const seconds = Math.floor((diffMs % (1000 * 60)) / 1000);

                                        return `${hours}h ${minutes}m ${seconds}s`;
                                      })()
                                    )}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'StartSoC', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'StopSoC', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'stop_reason', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'vehicle.make', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'vehicle.model', '-')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : _.get(item, 'customer_user_booked.name') ? (
                                      _.get(item, 'customer_user_booked.name', '-')
                                    ) : (
                                      _.get(item, 'invoice.ocpi_user.details.user_name', '-')
                                    )}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'invoice.ocpi_user') ? 'Yes' : 'No'}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : _.get(item, 'customer_user_booked.phone') ? (
                                      _.get(item, 'customer_user_booked.phone', '-')
                                    ) : (
                                      _.get(item, 'invoice.ocpi_user.details.phone', '-')
                                    )}
                                  </td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      (() => {
                                        const partyId = _.get(item, 'ocpiCredential.partyId', 'Chargzone App');
                                        switch (partyId) {
                                          case 'TCZ':
                                            return 'Chargezone';
                                          case 'HYD':
                                            return 'Hyundai';
                                          case 'EVN':
                                            return 'Fich';
                                          case 'BLU':
                                            return 'Blue Smart';
                                          case 'PUL':
                                            return 'Pulse';
                                          case 'STQ':
                                            return 'Statiq';
                                          default:
                                            return 'Chargezone';
                                        }
                                      })()
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(hmilreportlist) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.charging_station && delete values['charging_station'];
              !values.wallet_type && delete values['wallet_type'];
              !values.type && delete values['type'];
              if (values.charging_station === 'All' && values.wallet_type === 'All' && values.type === 'All') {
                const HMILCustomChargingReportData = {
                  ...values,
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                  wallet_type: '',
                  type: '',
                };
                HMILCustomChargingReport(HMILCustomChargingReportData);
              } else if (values.charging_station === 'All' && values.wallet_type === 'All') {
                const HMILCustomChargingReportData = {
                  ...values,
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                  wallet_type: '',
                };
                HMILCustomChargingReport(HMILCustomChargingReportData);
              } else if (values.charging_station === 'All' && values.type === 'All') {
                const HMILCustomChargingReportData = {
                  ...values,
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                  type: '',
                };
                HMILCustomChargingReport(HMILCustomChargingReportData);
              } else if (values.wallet_type === 'All' && values.type === 'All') {
                const HMILCustomChargingReportData = { ...values, wallet_type: '', type: '' };
                HMILCustomChargingReport(HMILCustomChargingReportData);
              } else if (values.charging_station === 'All') {
                const HMILCustomChargingReportData = { ...values, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
                HMILCustomChargingReport(HMILCustomChargingReportData);
              } else if (values.wallet_type === 'All') {
                const HMILCustomChargingReportData = { ...values, wallet_type: '' };
                HMILCustomChargingReport(HMILCustomChargingReportData);
              } else if (values.type === 'All') {
                const HMILCustomChargingReportData = { ...values, type: '' };
                HMILCustomChargingReport(HMILCustomChargingReportData);
              } else {
                HMILCustomChargingReport(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="canvas-selection__block">
                    <Select
                      label={t('filters.station')}
                      options={
                        !_.isEmpty(stationList)
                          ? !changeStation
                            ? !_.isEmpty(stationList) && [
                                ..._.map(stationList, (station) => {
                                  return { label: station.name, value: station.id };
                                }),
                              ]
                            : !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                          : !changeStation
                          ? !_.isEmpty(allStation) && [
                              ..._.map(allStation, (station) => {
                                return { label: station.name, value: station.id };
                              }),
                            ]
                          : !_.isEmpty(allStation) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('placeHolder.selectStation')}
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charging_station`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`charging_station`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue('charging_station', val);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      HMILCustomChargingReport();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default HMILCustomChargingReport;
