import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createPromiseAction } from '@adobe/redux-saga-promise';
import { FETCH_PROFILE, GET_PROFILE_SUCCESS } from 'actions/profile';
import PropTypes from 'prop-types';
import { cookie } from 'utils/cookies/cookies';
import MediaQuery from 'react-responsive';
import MainLayout from 'components/layout/index';
import { onMessageListener } from '../../firebase';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { MessagePopup } from 'App';
import { GET_DASHBOARD_DATA } from 'actions/dashboard';
import { FETCH_CHARGER_BY_STATION, GET_CHARGER } from 'actions/charger';
import { FETCH_NOTIFICATION } from 'actions/notification';
import moment from 'moment';
import { routesEntries } from 'utils/routes/index';
import { MANAGE_ROLE } from 'actions/role';
// import queryString from 'query-string';

export const RealTimeData = createContext();
export const NavContext = createContext();
const parseQueryString = (queryString) => {
  // Remove the leading "?" if present
  queryString = queryString.replace(/^\?/, '');

  // Split the query string into individual key-value pairs
  const pairs = queryString.split('&');

  // Create an object to store the key-value pairs
  const params = {};

  // Iterate through the pairs and populate the params object
  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    params[key] = value;
  });

  return params;
};

const PrivateRoute = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [userT, setUserT] = useState();
  const [realTimeMessageType, setRealTimeMessageType] = useState(false);
  const userData = useSelector((state) => state.profile.userProfile);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const excludedModules = useSelector((state) => state.manageRole?.excludedModules);
  const navigateTo = (path, state) => {
    if (path) {
      const queryParams = parseQueryString(path);
      const searchParams = new URLSearchParams(location.search);
      let paramsLength = Object.keys(queryParams).length;
      let tenant = searchParams.get('tenant');
      let newPath = path;
      let lastKey;
      for (const key in queryParams) {
        lastKey = key;
      }
      if (tenant && paramsLength >= 1) {
        if (queryParams[lastKey]) {
          newPath = newPath + '&tenant=' + tenant;
        } else {
          newPath = newPath + '?tenant=' + tenant;
        }
      }
      if (tenant && paramsLength == 0 && queryParams[lastKey]) {
        newPath = newPath + '?tenant=' + tenant;
      }
      history.push(newPath, state);
    }
  };

  const isAllowed = useCallback(
    (key) => {
      const excludedModulesids = excludedModules.map((exMod) => exMod.id);
      const allowModulesKeys =
        rolePermissionData?.[0]?.modules?.filter((module) => !excludedModulesids.includes(module.id))?.map((module) => module.key) || [];

      return allowModulesKeys.length > 0 && allowModulesKeys.includes(key);
    },
    [JSON.stringify(rolePermissionData), JSON.stringify(excludedModules)]
  );

  const getProfile = useCallback((data) => {
    const fetchPostAction = createPromiseAction(FETCH_PROFILE);
    return dispatch(fetchPostAction(data));
  }, []);

  const setUser = useCallback((data) => dispatch({ type: GET_PROFILE_SUCCESS, payload: data }), []);

  const getDashboard = useCallback(() => {
    dispatch({ type: GET_DASHBOARD_DATA });
  }, []);

  const getChargers = useCallback((data = {}) => {
    dispatch({ type: FETCH_CHARGER_BY_STATION, payload: data });
  }, []);

  const getChargerDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGER, payload: id });
  }, []);

  const getGeneralNotification = useCallback((data = {}) => {
    dispatch({ type: FETCH_NOTIFICATION, payload: data });
  }, []);

  React.useEffect(() => {
    if (userT?.role !== undefined && userT?.role !== '') {
      const data = {
        name: userT?.role,
      };
      dispatch({ type: MANAGE_ROLE, payload: data });
    }
  }, [userT]);

  const onMessageReceived = useCallback(
    (payload) => {
      setRealTimeMessageType(payload.data.type);
      if (payload.data.type === 'update_dashboard') {
        if (location.pathname === '/dashboard') {
          // call dashboard api
          getDashboard();
        } else if (payload.data.for === 'charger' && location.pathname === `/stations/${get(payload, 'data.station')}`) {
          // call charger by Station Api
          console.log('payload', payload);
          getChargers({ id: get(payload, 'data.station') });
        } else if (payload.data.for === 'charger' && location.pathname === `/charger/${get(payload, 'data.charger')}`) {
          // call charger Api
          getChargerDetail(get(payload, 'data.charger'));
        }
      } else {
        //show toast message
        if (location.pathname === '/notification') {
          const allNotification = {
            from: moment(moment().startOf('month')).format('YYYY-MM-DD HH:mm:ss'),
            to: moment(moment(new Date()).endOf('day')).format('YYYY-MM-DD HH:mm:ss'),
          };
          getGeneralNotification(allNotification);
        }
        const title = get(payload, 'data.title', '');
        const description = get(payload, 'data.description', '');
        const options = {
          autoClose: 6000,
          type: toast.TYPE.INFO,
          position: toast.POSITION.TOP_RIGHT,
          pauseOnHover: true,
        };
        if (title) {
          toast(<MessagePopup title={title} description={description} />, options);
        }
      }
    },
    [location]
  );

  onMessageListener(onMessageReceived)
    .then()
    .catch((err) => console.error('Failed: ', err));

  const tenantId = new URLSearchParams(location.search).get('tenant');
  const tenantSessionToken = localStorage.getItem(`${tenantId}_token`);
  let token = tenantId ? tenantSessionToken : cookie.get('token');

  useEffect(() => {
    if (token) {
      getProfile()
        .then((res) => {
          const user2 = res.data;
          setUserT(user2);
          setUser(user2);
        })
        .catch((err) => {
          console.error(err);
          cookie.clean();
          history.push('/login');
        });
    } else if (!token || !userData) {
      history.push('/login');
    }
  }, []);

  const renderRoutes = useCallback(() => {
    const excludedModulesids = excludedModules.map((exMod) => exMod.id);
    const allowModulesKeys =
      rolePermissionData?.[0]?.modules?.filter((module) => !excludedModulesids.includes(module.id))?.map((module) => module.key) || [];
    return routesEntries.map(([key, route]) => {
      const RouteComponent = route.component;
      if (
        route?.all ||
        (route?.showSuperAdmin && userT?.role && route.roles.includes(userT?.role)) ||
        (allowModulesKeys.length > 0 && allowModulesKeys.includes(route?.moduleKey))
      ) {
        return <Route key={key} exact path={route.path} component={RouteComponent} />;
      }
    });
  }, [userT, JSON.stringify(rolePermissionData), JSON.stringify(excludedModules)]);

  return (
    <NavContext.Provider value={{ navigateTo, isAllowed }}>
      <RealTimeData.Provider value={realTimeMessageType}>
        <MediaQuery minWidth={800}>
          {(matches) => {
            return <MainLayout showToggle={!matches}>{renderRoutes()}</MainLayout>;
          }}
        </MediaQuery>
      </RealTimeData.Provider>
    </NavContext.Provider>
  );
};

export default PrivateRoute;
PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.node]),
};

export const AdminRoute = ({ component: Component, realTimeMessageType, ...rest }) => {
  // const previousLocation = useRef(rest.location.pathname + rest.location.search);
  // const location = rest.location.pathname === '/logout' ? previousLocation.current : rest.location.pathname + rest.location.search;
  // localStorage.setItem('pathname', JSON.stringify(location));
  //
  // useEffect(() => {
  //   previousLocation.current = rest.location.pathname + rest.location.search;
  // }, [rest.location.pathname + rest.location.search]);

  return (
    <RealTimeData.Provider value={realTimeMessageType}>
      <Route
        {...rest}
        render={(props) => (
          <MediaQuery minWidth={800}>
            {(matches) => {
              return (
                <MainLayout {...props} showToggle={!matches}>
                  <Component {...props} />
                </MainLayout>
              );
            }}
          </MediaQuery>
        )}
      />
    </RealTimeData.Provider>
  );
};
AdminRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.node]),
  realTimeMessageType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
